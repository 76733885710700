<template>
  <NuxtLayout :name="layout">
    <div
      class="mx-auto flex h-full flex-col items-center justify-center"
      :class="[isPageNotFound || layout === 'auth' ? 'w-full' : 'w-1/3']"
    >
      <template v-if="isPageNotFound">
        <p class="max-w-xl pb-10 text-center">
          Sorry, we couldn't find the page you are looking for. Please try going back or
          click the button below to continue.
        </p>
        <UiButton
          label="Back home"
          :to="{name: 'index'}"
        />
      </template>
      <template v-else>
        <p class="max-w-xl pb-10 text-center">
          Sorry, something went wrong. Please try going back or click the button
          below to continue.
        </p>
        <UiButton
          label="Back home"
          :to="{name: 'index'}"
        />
      </template>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import {NuxtError} from '#app';

const props = defineProps<{
  error: NuxtError,
}>();

useHead({
  title: props.error.statusMessage,
});

const user = useSofieUserWhenAuthenticated();
const isPageNotFound = computed(() => props.error.statusCode === 404);

const layout = user?.value ? 'default' : 'auth';
</script>
